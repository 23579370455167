import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as data from './linechart.json';
import * as Chart from 'chart.js';

@Component({
    selector: 'app-task-line-graph',
    templateUrl: './task-line-graph.component.html',
    styleUrls: ['./task-line-graph.component.scss']
})
export class TaskLineGraphComponent {

  private grdcolors(){
    var  ctx:any= document.getElementById('lineChart1');
    if(ctx){
    var  drawchart=ctx.getContext("2d");
    var gradientStroke:any = drawchart.createLinearGradient(0, 60, 0, 110);
    gradientStroke.addColorStop(0, "rgba(2, 212, 127, .4)");
    gradientStroke.addColorStop(1, "rgba(2, 212, 127, .0)");
    return gradientStroke;
    }
  }

  chartColors = [{
    backgroundColor: "rgba(244, 144, 128, 0.8)",
    borderColor: '#02D47F'
   }]

  currentDay;
  currentTab = 0;

  tables = [
    {
      title: "Tasks completed this week", 
      data: data.lineOne,
      backgroundColor: 'rgba(1,212,127,.3)',
      borderColor: '#01D47F',
      button: 'Tasks'
    },
    {
      title: "Time logged this week", 
      data: data.lineTwo,
      backgroundColor: 'rgba(1,212,127,.3)',
      borderColor: '#01D47F',
      button: 'Time'
    }
  ]

  dataObj1 = {
    labels: ['', '', '', '', '', '', ''],
    datasets: [
          {
            pointRadius: 0,
            lineTension: .5,   
            data: this.tables[0].data,
            backgroundColor: [this.tables[0].backgroundColor],
            borderColor: [this.tables[0].borderColor],
            borderWidth: 3,
        }
    ]
  }

  dataObj2 = {
    labels: ['', '', '', '', '', '', ''],
    datasets: [
          {
            pointRadius: 0,
            lineTension: .5,   
            data: this.tables[1].data,
            backgroundColor: [this.tables[1].backgroundColor],
            borderColor: [this.tables[1].borderColor],
            borderWidth: 3,
        }
    ]
  }

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
    },
    legend: {
      display: false
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                fontColor: "rgb(33,167,238)",
                display: false
            },
            scaleLabel: {
              display: true,
              labelString: ""
            },
            gridLines: {
              display: false ,
              color: "rgba(33,167,238,.3)"
            },
        }],
        xAxes: [
          {
            ticks: {
              fontColor: "rgba(0,0,0,1)"
            },
            scaleLabel: {
              display: false,
              labelString: "Date"
            },
            gridLines: {
              display: false ,
              color: "rgba(6,50,83,.5)"
            },
          }
        ],
    }
  }

  getCurrentDay() {
    const today = new Date;
    this.currentDay = today.getDay()
  }

  selectTab(num) {
    this.currentTab = num
  }

  constructor(private http: HttpClient) {}

  ngOnInit() {

    this.getCurrentDay();
      
    let chart1 = new Chart('lineChart1', {});

    let chart2 = new Chart('lineChart2', {});
    
  }

  ngAfterViewChecked(){
    this.chartColors[0].backgroundColor = this.grdcolors()
  }
}