<!-- CASE / ARCHIVE ROUTE -->
<div id="case-list-container" *ngIf="currRoute === 'case' || currRoute === 'archive'">
    <form (submit)="searchCollection($event)">
        <input type="text" placeholder="Search for a case..." name="search" (keyup)="searchCollection($event)">
        <img src="../../../../assets/photos/black-search.svg" class="fa-search">
    </form>
    <div id="sort" (click)="clickShowSort()">
        <p>Sort by...</p>
    </div>
    <div id="sort-options" *ngIf="showSort">
        <ul>
            <li *ngFor="let item of sortArr" (click)="setSort($event)">
                {{item.option}}
            </li>
        </ul>
    </div>
    <div id="filter" (click)="clickShowFilter()"><img src="../../../assets/photos/filter.svg"></div>
    <div id="filter-options" *ngIf="showFilter">
        <ul>
            <li *ngFor="let item of filterArr" (click)="setFilter($event)">
                {{item.type}}
            </li>
        </ul>
    </div>
    <ul id="titles">
        <li>Case Name</li>
        <li>Type</li>
        <li>Progress</li>
        <li>Active Tasks</li>
        <li>Due Date</li>
        <li>Org</li>
        <li>Assigned to</li>
    </ul>
    <div id="case-list">
        <ul>
            <li *ngFor="let item of output | paginate: { itemsPerPage: 10, currentPage: p }">
                <div id="name"><a *ngIf="currRoute === 'case'" routerLink="details/{{item.id}}">{{item.name}}</a><a *ngIf="currRoute === 'archive'" routerLink="/case/details/{{item.id}}">{{item.name}}</a></div>
                <div id="type">{{item.type}}</div>
                <div id="progress">
                    <div>
                        <div id="progressBar" [ngStyle]="{'width': item.progress }"></div>
                    </div>
                </div>
                <div id="active">{{item.activeTasks}}</div>
                <div id="due">{{item.dueDate}}</div>
                <div id="org">{{item.org}}</div>
                <div id="assigned">
                    <div *ngFor="let user of item.assignedTo"><img src={{user.img}}></div>
                </div>
            </li>
        </ul>

        <div id="controls">
            <pagination-controls nextLabel=">" previousLabel="<" (pageChange)="p = $event"></pagination-controls>
        </div>
    </div>
</div>

<!-- HOME ROUTE -->

<div id="case-list-container-two" *ngIf="currRoute === 'home'">
    <div id="top-section">
        <h6 class="sub">Your Cases</h6>
        <div id="top-sub">
            <form (submit)="searchCollection($event)">
                <input type="text" placeholder="Search..." name="search" (keyup)="searchCollection($event)">
                <fa-icon [icon]="faSearch" class="fa fa-search"></fa-icon>
            </form>
            <div id="filter" (click)="clickShowFilter()"><img src="../../../assets/photos/filter.svg"></div>
            <div id="filter-options-home" *ngIf="showFilter">
                <ul>
                    <li *ngFor="let item of filterArr" (click)="setFilter($event)">
                        {{item.type}}
                    </li>
                </ul>
            </div>
            <div id="sort" (click)="clickShowSort()">
                <p>Sort by...</p>
            </div>
            <div id="sort-options-home" *ngIf="showSort">
                <ul>
                    <li *ngFor="let item of sortArr" (click)="setSort($event)">
                        {{item.option}}
                    </li>
                </ul>
            </div>
            

            <button (click)="changeValue()">Create New</button>
        </div>
    </div>
    <ul id="titles">
        <li>Case Name</li>
        <li>Type</li>
        <li>Progress</li>
        <li>Active Tasks</li>
        <li>Due Date</li>
        <li>Assigned to</li>
    </ul>
    <div id="case-list">
        <ul>
            <li *ngFor="let item of output | paginate: { itemsPerPage: this.items, currentPage: p }">
                <div id="name"><a routerLink="/case/details/{{item.id}}">{{item.name}}</a></div>
                <div id="type">{{item.type}}</div>
                <div id="progress">
                    <div>
                        <div id="progressBar" [ngStyle]="{'width': item.progress }"></div>
                    </div>
                </div>
                <div id="active">{{item.activeTasks}}</div>
                <div id="due">{{item.dueDate}}</div>
                <div id="assignedHome">
                    <div *ngFor="let user of item.assignedTo"><img src={{user.img}}></div>
                </div>
            </li>
        </ul>

        <div id="controlsHome">
            <pagination-controls nextLabel=">" previousLabel="<" (pageChange)="p = $event"></pagination-controls>
        </div>
    </div>
</div>