import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @ViewChild('adminNav', {static:false}) adminNav:ElementRef;

  constructor(private router: Router) { }
  onAdmin = false

  checkAdmin() {
    // this.onAdmin =  this.router.url.includes('admin')
    // console.log(this.router.url)
  }
  ngOnInit(): void {
    this.checkAdmin()
  }
  showNav(){
    this.adminNav.nativeElement.classList.add('showNav');
    this.checkAdmin()
  }
  hideNav(){
    this.adminNav.nativeElement.classList.remove('showNav');
    this.checkAdmin()
  }
}
