import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { TaskLineGraphComponent } from './task-line-graph/task-line-graph.component'
import { TaskBreakdownComponent } from './task-breakdown/task-breakdown.component';
import { DeadlinesComponent } from './deadlines/deadlines.component'


const routes: Routes = [
  { path: '', component: HomeDashboardComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }

export const HomeRoutingComponents = [
  HomeDashboardComponent,
  TaskLineGraphComponent,
  TaskBreakdownComponent,
  DeadlinesComponent
]