import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faSearch, faBell } from "@fortawesome/free-solid-svg-icons"
import { Router, NavigationEnd } from '@angular/router';
import { ProfileService} from '../../services/profile.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  faSearch = faSearch;
  faBell = faBell;
  bool: boolean = false;
  @Input() panelStatus: boolean;
  @Input() profStatus: boolean;
  invalid: any
  profile:any
  input: any
  currRoute: string;
  @Output() clickEvent = new EventEmitter<boolean>()
  @Output() profEvent = new EventEmitter<boolean>()

  constructor(private router: Router, private _profile:ProfileService) { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // get current route without leading slash `/`
        const eventUrl = /(?<=\/).+/.exec(event.urlAfterRedirects);
        const currentRoute = (eventUrl || []).join('');
        // set currRoute property with the value of the current route
        this.currRoute = currentRoute
        this.currRoute = this.currRoute.substring(0,6)
        console.log(this.currRoute)
      }
    });
  }

  ngOnInit(): void {
    this.getProfile()
  }
  inputEntry(event: KeyboardEvent) {
    if((event.target as HTMLInputElement).value.length > 0){
    this.input = (event.target as HTMLInputElement).value.replace(/\s/g, '+');
    (event.target as HTMLInputElement).placeholder = "Search"
    console.log(this.input)
    this.router.navigateByUrl(`search/${this.input}`);
    (event.target as HTMLInputElement).value = ""
    } else{
      (event.target as HTMLInputElement).placeholder = "Invalid Search Query"
      this.invalid = 'warning'
    }
  }
  
  logout() {
    this.router.navigateByUrl('server-connect');
  }
  toggleNotifications() {
    if (this.profStatus === true) {
      this.panelStatus = !this.panelStatus;
      this.clickEvent.emit(this.panelStatus)
      this.profEvent.emit(!this.profStatus)
    } else {
      this.panelStatus = !this.panelStatus;
      this.clickEvent.emit(this.panelStatus)
    }
  }
  toggleProfile() {
    if(this.panelStatus === true) {
    this.profStatus = !this.profStatus;
    this.profEvent.emit(this.profStatus)
    this.clickEvent.emit(!this.panelStatus)
    } else {
      this.profStatus = !this.profStatus;
      this.profEvent.emit(this.profStatus)
    }
  }
  getProfile(){
    this._profile.getProf().subscribe(data => {
      this.profile = []
      this.profile = data
     
      this.profile = this.profile.profile
      this.bool = true

      
    }
    )
    
  }

}
