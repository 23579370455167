<div id="notifications-container" *ngIf="panelStatus">
    <div id="triangle"></div>
    <div id="header">
        <h6> Notifications</h6>
    </div>
    <div id="close" (click)="changeValue()"><svg xmlns="http://www.w3.org/2000/svg" width="14.686" height="14.686"
            viewBox="0 0 14.686 14.686">
            <defs>
                <style>
                    .a {
                        fill: none;
                        stroke: #313131;
                        stroke-width: 3px;
                    }
                </style>
            </defs>
            <g transform="translate(-250.044 -434.436) rotate(45)">
                <line class="a" y2="17.769" transform="translate(494.385 121.5)" />
                <line class="a" y2="17.769" transform="translate(503.269 130.385) rotate(90)" />
            </g>
        </svg></div>
    <div #listDiv id="list">
        <div *ngIf="!fullArray">
        <div *ngFor="let noti of notiFive" id="noti-contain" >
            <div id="dot"></div>
            <p>{{noti.action}}</p>
            <p>{{noti.title}}</p>
            <p>{{noti.subAction}}</p>

        </div>
    </div>
    <div *ngIf="fullArray">
        <div *ngFor="let noti of notiArray" id="noti-contain" >
            <div id="dot"></div>
            <p>{{noti.action}}</p>
            <p>{{noti.title}}</p>
            <p>{{noti.subAction}}</p>

        </div>
    </div>
    </div>
    <div #bottomDiv id="bottom"><p (click)="showAll()">SHOW ALL</p></div>
</div>