<p class='chart-title'>{{ tables[currentTab].title }}</p>
<div class='table-toggle'>
    <button
        *ngFor='let table of tables; let i = index'
        [class.selected]='currentTab == i'
        (click)='selectTab(i)'
    >{{ table.button }}</button>
</div>

<div class="internal-bg chart-wrapper" [class.hidden]='currentTab !==0'>
    <canvas 
        baseChart 
        [datasets]='dataObj1.datasets' 
        [labels]="dataObj1.labels"
        [options]="chartOptions"
        id="lineChart1"
        [chartType]="'line'"
        [colors]='chartColors'
        [legend]="false"
    ></canvas>
    <div class='bottom-line'></div>
    <div class='chart-labels'>
        <p [class.today]='currentDay == 0'>S</p>
        <p [class.today]='currentDay == 1'>M</p>
        <p [class.today]='currentDay == 2'>T</p>
        <p [class.today]='currentDay == 3'>W</p>
        <p [class.today]='currentDay == 4'>T</p>
        <p [class.today]='currentDay == 5'>F</p>
        <p [class.today]='currentDay == 6'>S</p>
    </div>
</div>

<div class="internal-bg chart-wrapper" [class.hidden]='currentTab !==1'>
    <canvas 
        baseChart 
        [datasets]='dataObj2.datasets' 
        [labels]="dataObj2.labels"
        [options]="chartOptions"
        id="lineChart2"
        [chartType]="'line'"
        [colors]='chartColors'
        [legend]="false"
    ></canvas>
    <div class='bottom-line'></div>
    <div class='chart-labels'>
        <p [class.today]='currentDay == 0'>S</p>
        <p [class.today]='currentDay == 1'>M</p>
        <p [class.today]='currentDay == 2'>T</p>
        <p [class.today]='currentDay == 3'>W</p>
        <p [class.today]='currentDay == 4'>T</p>
        <p [class.today]='currentDay == 5'>F</p>
        <p [class.today]='currentDay == 6'>S</p>
    </div>
</div>