import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef  } from '@angular/core';
import { ProfileService} from '../../services/profile.service'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() profStatus: boolean;
  @Output() profStatusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  bool:boolean 
  profile:any;
  ctaArray:any = [];
  showInput: boolean;
  @ViewChild('courseInput', { static: false }) courseInput: ElementRef;

  constructor(private _profile:ProfileService) { }

  ngOnInit(): void {
    this.getProfile()
  }
  changeValue() {
    this.profStatus = !this.profStatus;
    this.profStatusChange.emit(this.profStatus)
  }
  getProfile(){
    this._profile.getProf().subscribe(data => {
      this.profile = []
      this.profile = data
      
      this.profile = this.profile.profile
      this.ctaArray = this.profile.courses
      this.bool = true;
      
    }
    )  
  }
  addCta() {
    if (!this.courseInput) {
      this.showCtaInput()
    } else {

      let day = new Date().getDate()
    let month = new Date().getMonth() - 1;
    let year = new Date().getFullYear()
    let dateString = day + "/" + month + "/" + year;
    let newCta = { name: this.courseInput.nativeElement.value, date: dateString}
    
    let arr = []
    arr.push(newCta, ...this.ctaArray)
    this.ctaArray = arr;
   
    this.courseInput.nativeElement.value = '';
    this.showInput = !this.showInput;
    }
  
  }
  showCtaInput(){
    this.showInput =! this.showInput
  }
  

}
