import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import * as data from '../../../../assets/json/tasks.json'

@Component({
  selector: 'app-deadlines',
  templateUrl: './deadlines.component.html',
  styleUrls: ['./deadlines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeadlinesComponent implements OnInit {

  tasks = data.details;

  constructor() { }

  ngOnInit(): void {
  }

}
