import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CaseFilesService {
  @Injectable()
  caseFiles = [];
  
  constructor() {
  }
}
