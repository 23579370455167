<header id="internal-header" *ngIf="bool">
    <div id="search" *ngIf="currRoute !== 'search'">
        <input type="text" placeholder="Search" name="search" (keyup.enter)="inputEntry($event)" >
        <img src="../../../../assets/photos/grey-search.svg" class="fa-search">
    </div>
    <div id="user-profile">
        <div class="bell" (click)="toggleNotifications()">
            <fa-icon [icon]="faBell"></fa-icon>
            <div class="indicator"></div>
        </div>
        <span class="photo" (click)="toggleProfile()">
            <img src="{{profile.photo}}" />
        </span>
        <span class="user-name">
            {{profile.name}}
        </span>
        <span id="exit" (click)="logout()">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1078.31 1078.31"><defs><style>.cls-1{fill:#ed3b3e;}</style></defs><title>Exit App</title><g id="Icon_feather-log-out" data-name="Icon feather-log-out"><path class="cls-1" d="M386.5,1078.31H183C82.48,1078.31.69,996.52.69,896V182.31C.69,81.79,82.48,0,183,0H386.5a80.38,80.38,0,0,1,0,160.76H183a21.57,21.57,0,0,0-21.55,21.55V896A21.57,21.57,0,0,0,183,917.55H386.5a80.38,80.38,0,1,1,0,160.76Z" transform="translate(-0.69)"/><path class="cls-1" d="M1072.75,570.08a80.49,80.49,0,0,0-17.37-87.87L800.57,227.48A80.37,80.37,0,0,0,686.91,341.15L804.52,458.73H386.89a80.39,80.39,0,0,0,0,160.77H804.55L686.91,737.16A80.37,80.37,0,1,0,800.57,850.83L1055.38,596A80.28,80.28,0,0,0,1072.75,570.08Z" transform="translate(-0.69)"/></g></svg>
        </span>
    </div>
</header>
