<div id="sidebar">
    <a id="conseqta-logo">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.95 113.12"><defs></defs><title>Conseqta</title><path d="M150.27,6.24a14.34,14.34,0,0,0,9.78,8.36,7.33,7.33,0,0,0,2.84.08l.87-.23c2-.73,2.92-2.65,2.12-5.3-1.24-3.7-5.51-7.58-9.65-8.83-4.34-1.16-7.18,1-6.27,4.85a8.06,8.06,0,0,1,.29,1.07ZM116,15.9c-4,6.39-4,6.39-8,13.22-2.52,4.13-2.52,4.13-5,8.35-6.16,6.55-15.06,2.18-18.3.22-.77-.5-1.24-.84-1.24-.84a11,11,0,0,0-10.82.37,11.26,11.26,0,0,0-4.45,14.89,10.66,10.66,0,0,0,14.5,4.11l.48-.29s.47-.37,1.38-1c3.35-2.15,12.41-6.74,18.67.22l5.27,9.36c4.59,8.19,4.59,8.19,9.18,16.54a4.65,4.65,0,0,0,4.13,2.58c8.89.11,8.89.11,17.61.26,4.92,0,4.92,0,9.77.11,8.12,2.22,8.67,12.41,8.54,16.55a9.31,9.31,0,0,1-.11,1.57,11.34,11.34,0,0,0,4.64,9.62c4.39,2.91,9.89.88,12.41-4.41s1.06-11.87-3.06-14.93l-1.08-.77c-2.84-1.88-10-7.63-7.8-16.37,2-4.39,2-4.39,3.9-8.65,3.1-7.2,3.1-7.2,5.94-14a5.24,5.24,0,0,0-.47-4.4c-4.13-7.32-4.13-7.32-8.49-14.56-2.59-4.14-2.59-4.14-5.18-8.2-1.89-2.87-1.89-2.87-3.79-5.67A5.53,5.53,0,0,0,151,17.47l-6.32-.93c-4.37-.58-4.37-.58-8.86-1-7.94-.73-7.94-.73-16.1-1.31A3.75,3.75,0,0,0,116,15.92Zm.71,27.36,7.58-13.41a5.12,5.12,0,0,1,5.06-2.57c7.69.58,7.69.58,15.16,1.3a6.89,6.89,0,0,1,5.06,3.29c4.3,6.9,4.3,6.9,8.43,14a6.58,6.58,0,0,1,.51,6.09c-3.13,6.78-3.13,6.78-6.52,13.78a5.14,5.14,0,0,1-5,3c-7.91-.28-7.91-.28-16-.51a6.43,6.43,0,0,1-5.52-3.49c-4.3-7.61-4.3-7.61-8.68-15.08a6.23,6.23,0,0,1-.1-6.4Z" transform="translate(-66.88 0)"/></svg>
    </a>
    <a class='home' routerLink="home" (click)="hideNav()" [routerLinkActive]="['active-route']">
        <svg xmlns="http://www.w3.org/2000/svg" width="21.827" height="21.878" viewBox="0 0 21.827 21.878"><defs></defs><path class="a" d="M217.767,137.289V149.05a1.134,1.134,0,0,1-1.134,1.134h-4.794a1.134,1.134,0,0,1-1.134-1.134v-4.319a3.429,3.429,0,0,0-3.429-3.429h0a3.429,3.429,0,0,0-3.429,3.429v4.319a1.134,1.134,0,0,1-1.134,1.134h-5.637a1.134,1.134,0,0,1-1.134-1.134V137.294a2.564,2.564,0,0,1,1.033-2.057l8.847-6.588a1.733,1.733,0,0,1,2.071,0l8.847,6.591A2.555,2.555,0,0,1,217.767,137.289Z" transform="translate(-195.94 -128.306)"/></svg>       HOME
    </a>
    <a class='case' routerLink="case" [routerLinkActive]="['active-route']" (click)="hideNav()">
        <svg xmlns="http://www.w3.org/2000/svg" width="25.302" height="20.694" viewBox="0 0 25.302 20.694"><defs></defs><g class="a" transform="translate(8.65)"><rect class="d" width="9" height="7" rx="3"/><rect class="e" x="1" y="1" width="7" height="5" rx="2"/></g><rect class="b" width="22.113" height="14.866" rx="1" transform="translate(1.538 4.828)"/><line class="c" x2="25.302" transform="translate(0 9.3)"/></svg>       DETAILS
    </a>
    <a class='tasks' routerLink="tasks" [routerLinkActive]="['active-route']" (click)="hideNav()">
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="25.661" viewBox="0 0 19 25.661"><defs></defs><g transform="translate(0 0.75)"><rect class="a" width="19" height="23" rx="2" transform="translate(0 1.911)"/><rect class="b" width="10" height="4" rx="1" transform="translate(4.5)"/><line class="c" x2="10" transform="translate(4.5 10.5)"/><line class="c" x2="10" transform="translate(4.5 19.05)"/><line class="c" x2="10" transform="translate(4.5 14.775)"/></g></svg>        TASKS
    </a>
    <a clas='archive' routerLink="archive" [routerLinkActive]="['active-route']" (click)="hideNav()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.999" height="17" viewBox="0 0 16.999 17"><defs></defs><path class="a" d="M16,17H1a1,1,0,0,1-1-1V3.778H17V16A1,1,0,0,1,16,17ZM5.918,6.611a.25.25,0,0,0-.25.249V8.25a.25.25,0,0,0,.25.249h5.166a.25.25,0,0,0,.25-.249V6.861a.25.25,0,0,0-.25-.249ZM17,2.834H0V1A1,1,0,0,1,1,0H16a1,1,0,0,1,1,1V2.833Z" transform="translate(-0.001)"/></svg>       ARCHIVE
    </a>
    <a clas='reports' routerLink="reports" [routerLinkActive]="['active-route']" (click)="hideNav()">
        <svg xmlns="http://www.w3.org/2000/svg" width="20.999" height="14.999" viewBox="0 0 20.999 14.999"><defs></defs><path class="a" d="M9,15H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H9a1,1,0,0,1,1,1V2H20a1,1,0,0,1,1,1V14a1,1,0,0,1-1,1Z" transform="translate(-0.001)"/></svg>        REPORTS
    </a>
    <a class='corr' routerLink="corr" [routerLinkActive]="['active-route']" (click)="hideNav()">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.18" height="15.678" viewBox="0 0 22.18 15.678"><defs></defs><g transform="translate(1.046 0.537)"><rect class="a" width="20" height="15" rx="1" transform="translate(0.05 0.141)"/><path class="b" d="M9838.125,257l10.061,7.207,10.029-6.976" transform="translate(-9838.125 -256.488)"/></g></svg>        CORR
    </a>
    <a clas='teams' routerLink="teams" [routerLinkActive]="['active-route']" (click)="hideNav()">
        <svg xmlns="http://www.w3.org/2000/svg" width="18.571" height="13" viewBox="0 0 18.571 13"><defs></defs><path class="a" d="M2.786,7.821A1.857,1.857,0,1,0,.929,5.964,1.859,1.859,0,0,0,2.786,7.821Zm13,0a1.857,1.857,0,1,0-1.857-1.857A1.859,1.859,0,0,0,15.786,7.821Zm.929.929H14.857a1.852,1.852,0,0,0-1.309.54,4.245,4.245,0,0,1,2.179,3.175h1.915a.928.928,0,0,0,.929-.929v-.929A1.859,1.859,0,0,0,16.714,8.75Zm-7.429,0A3.25,3.25,0,1,0,6.036,5.5,3.248,3.248,0,0,0,9.286,8.75Zm2.229.929h-.241a4.487,4.487,0,0,1-3.975,0H7.057a3.344,3.344,0,0,0-3.343,3.343v.836A1.393,1.393,0,0,0,5.107,15.25h8.357a1.393,1.393,0,0,0,1.393-1.393v-.836A3.344,3.344,0,0,0,11.514,9.679ZM5.023,9.29a1.852,1.852,0,0,0-1.309-.54H1.857A1.859,1.859,0,0,0,0,10.607v.929a.928.928,0,0,0,.929.929H2.841A4.255,4.255,0,0,1,5.023,9.29Z" transform="translate(0 -2.25)"/></svg>       TEAMS
    </a>
    <a clas='metrics' routerLink="metrics" [routerLinkActive]="['active-route']" (click)="hideNav()">
        <svg xmlns="http://www.w3.org/2000/svg" width="19.506" height="18.113" viewBox="0 0 19.506 18.113"><defs></defs><g class="a" transform="translate(-2.25 -3.375)"><path class="b" d="M17.373,12.312l-3.8,5.807a2.068,2.068,0,0,1,.47,1.319,2.089,2.089,0,1,1-4.179,0A2.29,2.29,0,0,1,9.9,19.05L7.4,17.592a2.077,2.077,0,0,1-1.45.583,2.1,2.1,0,0,1-1.027-.27L2.25,20.4v2.49a1.863,1.863,0,0,0,1.859,1.859H19.9a1.863,1.863,0,0,0,1.859-1.859V13.639l-2.551-1.693a2.088,2.088,0,0,1-1.833.366Z" transform="translate(0 -3.265)"/><path class="b" d="M5.95,10.732A2.09,2.09,0,0,1,8.04,12.821c0,.091-.009.183-.017.27l2.616,1.463a2.071,2.071,0,0,1,1.323-.47,2.186,2.186,0,0,1,.453.048l3.8-5.894a2.067,2.067,0,0,1-.387-1.21,2.089,2.089,0,1,1,4.179,0,2.271,2.271,0,0,1-.057.492l1.8,1.2V5.234a1.863,1.863,0,0,0-1.859-1.859H4.109A1.863,1.863,0,0,0,2.25,5.234v9.982l1.741-1.667A2.089,2.089,0,0,1,5.95,10.732Z"/></g></svg>        WORK METRICS
    </a>
    <a clas='admin' routerLink="admin" [routerLinkActive]="['active-route']" [class.active-route]='onAdmin' (mouseenter)="showNav()" (mouseleave)="hideNav()">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.348" height="22.541" viewBox="0 0 22.348 22.541"><defs></defs><path class="a" d="M1.936,22.541a1.262,1.262,0,0,1,0-2.524H3.007V8.341H1A1,1,0,0,1,.473,6.493L10.645.151a1,1,0,0,1,1.057,0L21.875,6.493a1,1,0,0,1-.529,1.849H19.632V20.016h.78a1.262,1.262,0,0,1,0,2.524Zm15.071-2.524V8.341H14.965V20.016Zm-4.667,0V8.341H10.3V20.016Zm-4.666,0V8.341H5.632V20.016Z" transform="translate(0)"/></svg>        ADMIN
    </a>

</div>
<div (mouseenter)="showNav()" (mouseleave)="hideNav()" #adminNav id="admin-nav">
    <ul>
        <li><a routerLink="admin">Dashboard</a></li>
        <li><a routerLink="admin/settings">Settings</a></li>
        <li><a routerLink="admin/users">Users</a></li>
        <li><a routerLink="admin/cmmns">CMMNs</a></li>
    </ul>

</div>