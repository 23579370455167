import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CaseFormServiceService } from '../../../services/case-form-service.service'
import { CaseFilesService } from '../shared-services/case-files.service'
import { CasePlanService } from '../shared-services/case-plan.service'

@Component({
  selector: 'app-case-form',
  templateUrl: './case-form.component.html',
  styleUrls: ['./case-form.component.scss']
})
export class CaseFormComponent implements OnInit {
  @ViewChild('caseTag', { static: false }) caseTag: ElementRef;
  @ViewChild('tagClose', { static: false }) tagClose: ElementRef;
  dropDowns: any = [
    {
      show: false,
      selected: 'DD',
      dd: [{ day: 1 }, { day: 2 }, { day: 3 }, { day: 4 }, { day: 5 }, { day: 6 }, { day: 7 }, { day: 8 }, { day: 9 }, { day: 10 },
      { day: 12 }, { day: 13 }, { day: 14 }, { day: 15 }, { day: 16 }, { day: 17 }, { day: 18 }, { day: 19 }, { day: 20 }, { day: 21 },
      { day: 22 }, { day: 23 }, { day: 24 }, { day: 25 }, { day: 26 }, { day: 27 }, { day: 28 }, { day: 29 }, { day: 30 }, { day: 31 }]
    },
    {
      show: false,
      selected: 'YYYY',
      dd: [{ year: 2020 }, { year: 2021 }, { year: 2022 }, { year: 2023 }, { year: 2024 }, { year: 2025 },
      { year: 2026 }, { year: 2027 }, { year: 2028 }, { year: 2029 }, { year: 2030 }]
    },
    {
      show: false,
      selected: 'MM',
      dd: [{ month: 1 }, { month: 2 }, { month: 3 }, { month: 4 }, { month: 5 }, { month: 6 },
      { month: 7 }, { month: 8 }, { month: 9 }, { month: 10 }, { month: 11 }, { month: 12 }]
    },
    {
      show: false,
      selected: 'Investigation',
      dd: [{ type: 'Investigation' },
      { type: 'Audit' },
      // { type: 'Ticket' },
      { type: 'Allegation' }]
    },
    {
      show: false,
      selected: 'Type One',
      dd: [{ type: 'Type One' },
      { type: 'Type Two' },
      { type: 'Type Three' },
      { type: 'Type Four' }]
    },
    {
      show: false,
      selected: 'Type One',
      dd: [{ type: 'Type One' },
      { type: 'Type Two' },
      { type: 'Type Three' },
      { type: 'Type Four' }]
    },
    {
      show: false,
      selected: 'Source One',
      dd: [
        {
        type: 'Source One'
        },
        {
          type: 'Source Two'
        },
        {
          type: 'Source Three'
        },
      ]
    }

  ]
  inputs: [
    { value:string},
    { value:string},
    { value:string},
    { value:string},
    { value:string}
  ]
  caseTags = []
  
  selectedDate = new Date(this.dropDowns[1].selected, this.dropDowns[2].selected - 1, this.dropDowns[0].selected)

  constructor(public _form: CaseFormServiceService, public _caseFiles: CaseFilesService, public _casePlans: CasePlanService) { }

  ngOnInit(): void {
    

  }
  inputEntry(event: KeyboardEvent, i:number) {
    this.inputs[i].value = (event.target as HTMLInputElement).value.toUpperCase();
  }

  selectItem(event:any, i:number){
    this.dropDowns[i].show = false;
    this.dropDowns[i].selected = event.target.innerText;
  }

  toggleMenu(event: any, i: number){
    this.dropDowns[i].show =!this.dropDowns[i].show
    for(let j = 0; j < this.dropDowns.length; j++){
        if(this.dropDowns[j] === this.dropDowns[i]){
          continue;
        }
        this.dropDowns[j].show = false
    }
  }

  //click x button to close form
  changeValue() {
    this._form.showForm = !this._form.showForm
  }

  // ADD CASE TAG
  addTag() {
    
    let newCase = { case: this.caseTag.nativeElement.value }
    let arr = []
    arr.push(newCase, ...this.caseTags)
   
    this.caseTags = arr;
    this.caseTag.nativeElement.value = '';
  
  
  }

  // REMOVE CASE TAG
  removeTag(value){
    let arr = this.caseTags.filter( tag =>{
   
      return tag.case !== value
    })
    this.caseTags = arr
  }
  onSubmit() {
    let pseudoPackage = {
      'package': [
        {
          'casePlans': this._casePlans.casePlan
        },
        {
          'date': this.selectedDate
        }
      ]
    }
    this._form.showForm = !this._form.showForm
    console.log(pseudoPackage)
  }



}
