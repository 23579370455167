import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { DateInfoService } from '../../calendar/date-info.service'


@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.scss']
})
export class HomeDashboardComponent implements OnInit {

  currentDay = this.dateInfoService.currentDay
  weeksEvents;
  availableTimes = [7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5];

  @ViewChild('adminNav', {static:false}) adminNav:ElementRef;
  
  calculateWeek(num) {
    return Math.floor((num+this.currentDay.startsOn)/7)
  }

  setEventsForWeek() {
    this.weeksEvents = this.currentDay.events.filter(event => this.calculateWeek(event.day) == this.currentDay.week)
  }

  calculateEventX(startTime) {
    const rowPercent = 100/this.availableTimes.length;
    const rowStart = this.availableTimes[0];
    const minute = parseInt(startTime.slice(2,4))/60;
    const hour = parseInt(startTime.slice(0, 2));
    const time = minute+hour;
    return rowPercent * (time - rowStart)
  }

  calculateEventY(num) {
    // const rowPercent = 100/this.availableTimes.length;
    // const rowStart = this.availableTimes[0];
    // const minute = parseInt(startTime.slice(2,4))/60;
    // const hour = parseInt(startTime.slice(0, 2));
    // const time = minute+hour;
    // return rowPercent * (time - rowStart)
    let dayOfWeek = num + this.currentDay.startsOn - (this.calculateWeek(num)*7)
    return 14.3 * dayOfWeek
  }

  calculateEventWidth(start, end) {
    const rowPercent = 100/this.availableTimes.length;
    let startNum = parseInt(start.slice(2,4))/60 + parseInt(start.slice(0, 2));
    let endNum = parseInt(end.slice(2,4))/60 + parseInt(end.slice(0, 2));
    return (endNum - startNum)*rowPercent
  }

  getEventColor1(type) {
    return this.dateInfoService.eventColors[type].color1
  }

  convertTime(time) {
    let hour = time.toString().slice(0, 2);
    hour = hour > 12 ? hour - 12 : hour;
    hour = hour[0] == '0' ? hour.substr(1) : hour
    const minute = time.slice(2, 4);
    return `${hour}:${minute}`;
  }

  showTooltip(event){
    event.target.parentElement.children[1].classList.remove('hidden');
    // console.log('enter')
    console.log(event.target.parentElement.children[1])
  }
  hideTooltip(event){
    event.target.parentElement.children[1].classList.add('hidden');
  }

  constructor(private dateInfoService: DateInfoService) { }

  ngOnInit(): void {
    this.dateInfoService.gotToToday();
    this.setEventsForWeek();
  }

}
