import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';



const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'case',
    loadChildren: () => import('./modules/case/case.module').then(m => m.CaseModule)
  },
  {
    path: 'tasks',
    loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule)
  },
  {
    path: 'archive',
    loadChildren: () => import('./modules/archive/archive.module').then(m => m.ArchiveModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'corr',
    loadChildren: () => import('./modules/corr/corr.module').then(m => m.CorrModule)
  },
  {
    path: 'teams',
    loadChildren: () => import('./modules/teams/teams.module').then(m => m.TeamsModule)
  },
  {
    path: 'search/:id',
    loadChildren: () => import('./modules/global-search/global-search.module').then(m => m.GlobalSearchModule)
  },
  {
    path: 'metrics',
    loadChildren: () => import('./modules/metrics/metrics.module').then(m => m.MetricsModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./modules/calendar/calendar.module').then(m => m.CalendarModule)
  },
  {
    path: 'server-connect',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    data: {
      showElements: false
    }
  }, {
    path: '',
    redirectTo: 'server-connect',
    pathMatch: 'full'
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const AppRoutingComponents = [
  SidebarComponent,
  HeaderComponent,

]
