import { Component, OnInit } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { CasePlanService } from '../shared-services/case-plan.service'
import { faTrash, faFile } from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: 'app-case-plan-file',
  templateUrl: './case-plan-file.component.html',
  styleUrls: ['./case-plan-file.component.scss']
})
export class CasePlanFileComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  constructor(public _casePlans: CasePlanService) { }
  faTrash = faTrash
  faFile = faFile
  ngOnInit(): void {
  }
  sendFiles(file){
    this._casePlans.casePlan.push(file)
  }

  //==== FILE UPLOADER ===/
  

  public dropped(files: NgxFileDropEntry[]) {
    this.files = this.files.concat(files);
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          console.log(droppedFile.relativePath, file);
          this.sendFiles(file)
          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)
 
          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })
 
          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  public removeFile(i) {
    this.files.splice(i, 1);
  }

}
