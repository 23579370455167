import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CaseService {
  
    constructor(private http: HttpClient){}
    getCases(){
      
      return this.http.get('../../assets/json/case.json')
      
    }

}