<div id="profile-container" *ngIf="profStatus">
    <div *ngIf="bool">
    <div id="triangle"></div>
    <div id="header">
        <h6>User Profile</h6>
    </div>
    <div id="close" (click)="changeValue()"><svg xmlns="http://www.w3.org/2000/svg" width="14.686" height="14.686"
            viewBox="0 0 14.686 14.686">
            <defs>
                <style>
                    .a {
                        fill: none;
                        stroke:#313131;
                        stroke-width:3px;
                    }
                </style>
            </defs>
            <g transform="translate(-250.044 -434.436) rotate(45)">
                <line class="a" y2="17.769" transform="translate(494.385 121.5)" />
                <line class="a" y2="17.769" transform="translate(503.269 130.385) rotate(90)" />
            </g>
        </svg></div>
    <div  id="profile-contain">
        <div id="top-section">
            <img src="{{profile.photo}}">
            <div id="info">
                <p>{{profile.name}}</p>
                <p>{{profile.email}}</p>
                <p>{{profile.job}}</p>
            </div>
        </div>
        <div id="bottom-section" >
            
            <div id="svgPlus" (click)="addCta()"><img src="../../../assets/photos/circle.svg"></div>
            <input #courseInput id="courseInput"  (keyup.enter)="addCta()" *ngIf="showInput">
            <p>Your CTA Courses</p>
            <div id="line"></div>
            <div *ngFor="let course of ctaArray" id="course-contain">
                <p>{{course.name}}</p>
                <p>{{course.date}}</p>
            </div>
        </div>
    </div>
    </div>

</div>