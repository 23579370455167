import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { CaseFilesService } from '../shared-services/case-files.service'
import { faTrash, faFile } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-case-files-file',
  templateUrl: './case-files-file.component.html',
  styleUrls: ['./case-files-file.component.scss']
})
export class CaseFilesFileComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  
  faTrash = faTrash
  faFile = faFile
  constructor(public _caseFiles: CaseFilesService) { }

  ngOnInit(): void {
  }
  sendFiles(file){
    this._caseFiles.caseFiles.push(file)
  }
  
//==== FILE UPLOADER ===/


public dropped(files: NgxFileDropEntry[]) {
  this.files = this.files.concat(files);
  for (const droppedFile of files) {

    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        // Here you can access the real file
        console.log(droppedFile.relativePath, file);
        console.log(this.files)
        this.sendFiles(file)
        /**
        // You could upload it like this:
        const formData = new FormData()
        formData.append('logo', file, relativePath)

        // Headers
        const headers = new HttpHeaders({
          'security-token': 'mytoken'
        })

        this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
        .subscribe(data => {
          // Sanitized logo returned from backend
        })
        **/

      });
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      console.log(droppedFile.relativePath, fileEntry);
    }
  }
}

public fileOver(event) {
  console.log(event);
}

public fileLeave(event) {
  console.log(event);
}

public removeFile(i) {
  this.files.splice(i, 1);
}

}
