import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CaseFormComponent } from './case-form/case-form.component';
import { CaseListComponent } from './case-list/case-list.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CasePlanFileComponent } from './case-plan-file/case-plan-file.component';
import { CaseFilesFileComponent } from './case-files-file/case-files-file.component';
import { TaskFormComponent } from './task-form/task-form.component';
import { TaskFilesFileComponent } from './task-files-file/task-files-file.component';
import { TeamsFormComponent } from './teams-form/teams-form.component';
import { DetailsSidebarComponent } from './details-sidebar/details-sidebar.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CaseListComponent, CaseFormComponent, CasePlanFileComponent, CaseFilesFileComponent, TaskFormComponent, TaskFilesFileComponent, TeamsFormComponent, DetailsSidebarComponent],
  imports: [
    NgxPaginationModule,
    FontAwesomeModule,
    NgxFileDropModule,
    CommonModule,
    RouterModule
    
  ],
  exports: [CaseListComponent, CaseFormComponent, TaskFormComponent, TeamsFormComponent, DetailsSidebarComponent, CaseFilesFileComponent]
})
export class SharedModule { }
