<ngx-file-drop dropZoneClassName="file-drop" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
    (onFileLeave)="fileLeave($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div file-drop-inside>
            <button id="upload-button" type="button" (click)="openFileSelector()">Upload Case
                Plan</button>
        </div>
    </ng-template>
</ngx-file-drop>


<div class="upload-table">
    <div *ngFor="let item of files; let i=index">
                                    
        <div>
        <fa-icon [icon]="faFile" class="fa fa-file" ></fa-icon>
        <p>{{ item.relativePath }}</p>
        </div>
        <fa-icon [icon]="faTrash" class="fa fa-trash" (click)='removeFile(i)'></fa-icon>
    
</div>
</div>