<div class='deadlines'>
  <div class='title'>Your Upcoming Deadlines</div>
  <cdk-virtual-scroll-viewport itemSize="50" class="viewport">
    <div *cdkVirtualFor="let task of tasks" class="task">
      <div class='info left'>
        <a routerLink="task">{{ task.name }}</a>
        <p>{{ task.case }}</p>
      </div>
      <div class='info right'>
        <p>{{ task.dueDate }}</p>
        <p>{{ task.type }}</p>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</div>