<p class="chart-title">Task Breakdown by Case</p>
<div id="areaChart" class="internal-bg">
        <canvas 
            id="pie-chart"
            baseChart 
            [datasets]='dataObj.datasets' 
            [labels]="dataObj.labels"
            [options]="chartOptions"
            [chartType]="'doughnut'"
            [legend]="true"
        ></canvas>
</div>