import { Injectable } from '@angular/core';
import * as userEventData from './userEvents.json'

@Injectable({
  providedIn: 'root'
})
export class DateInfoService {

  monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];

  currentDay = {
    week: 0,
    month: 0,
    monthName: 'January',
    startsOn: 0,
    endsOn: 0,
    year: 2020,
    daysArray: new Array(42),
    weeksArray: [0, 7, 14, 21, 28, 35],
    events: [],
    unfilteredEvents: [],
    eventsCount: new Array(42),
    formOpened: false
  }

  newEventInfo = {
    newEventTime: [
      {
        element:"1:30 pm",
        value : "1130"
      },
      {
      element:"5:00 pm",
      value : "1700"
      }
    ],
    newEventDate: new Date(),
    tempEvent: false
  }

  currentFilter = [];

  eventTypes = [
    {
      name: 'audit',
      color1: '#00759F',
      color2: '#D9EEF5'
    },
    {
      name: 'interview',
      color1: '#81D36A',
      color2: '#E1FADA'
    },
    {
      name: 'site visit',
      color1: '#06C6E0',
      color2: '#CBF6FC'
    },
    {
      name: 'annual audit plan',
      color1: '#01D47F',
      color2: '#CAF8E6'
    }
  ]

  eventColors = {
    'audit' : {
      color1: '#00759F',
      color2: '#D9EEF5'
    },
    'interview' : {
      color1: '#81D36A',
      color2: '#E1FADA'
    },
    'site visit' : {
      color1: '#06C6E0',
      color2: '#CBF6FC'
    },
    'annual audit plan' : {
      color1: '#01D47F',
      color2: '#CAF8E6'
    }
  }

  approachingMonth = {
    month: 0,
    monthName: 'January',
    startsOn: 0,
    endsOn: 0,
    year: 2020,
    daysArray: new Array(42),
    events: [],
  }

  lastMonth = {
    month: 0,
    monthName: 'January',
    startsOn: 0,
    endsOn: 0,
    year: 2020,
    daysArray: new Array(42),
    events: [],
  }

  userEvents = userEventData.userEvents
  today;

  getFirstDay(year, month) {
    return new Date(year, month).getDay();
  }

  getDaysInMonth(year, month) {
    return (32 - new Date(year, month, 32).getDate());
  }

  getEventsForMonth(year, month) {
    const eventsForMonth = this.userEvents.filter(event => (event.year == year && event.month == month && this.applyFilter(event)));
    return eventsForMonth;
  }

  applyFilter(event) {
    let noMatch = true
    this.currentFilter.forEach(filter => {
      if (event.type == filter) noMatch = false
    })
    return noMatch
  }

  addToFilter(type) {
    this.currentFilter.push(type)
    this.currentDay.events = this.currentDay.events.filter(event => this.applyFilter(event))
  }

  removeFromFilter(type) {
    this.currentFilter = this.currentFilter.filter(filter => filter !== type)
    this.currentDay.events = this.currentDay.unfilteredEvents.filter(event => this.applyFilter(event))
  }

  setCurrentWeek(week) {
    this.currentDay.week = week
  }

  getWeekFromDate(date) {
    const dateNum = date.getDate()
    const dayOfWeek = date.getDay()
    let week = Math.floor((dateNum+dayOfWeek)/7)
    return week
  }

  setApproachingMonth(year, month) {
    if (month == 11) {
      month = 0;
      year++
    } else { month++}
    this.approachingMonth.month = month;
    this.approachingMonth.monthName = this.monthNames[month];
    this.approachingMonth.startsOn = this.getFirstDay(year, month);
    this.approachingMonth.endsOn = this.getDaysInMonth(year, month);
    this.approachingMonth.events = this.getEventsForMonth(year, month);
  }

  setLastMonth(year, month) {
    if (month == 0) {
      month = 11;
      year--
    } else { month--}
    this.lastMonth.month = month;
    this.lastMonth.monthName = this.monthNames[month];
    this.lastMonth.startsOn = this.getFirstDay(year, month);
    this.lastMonth.endsOn = this.getDaysInMonth(year, month);
    this.lastMonth.events = this.getEventsForMonth(year, month);
  }

  setcurrentDay(year, month) {
    this.currentDay.daysArray = new Array(42);
    this.currentDay.eventsCount = new Array(42);
    this.currentDay.monthName = this.monthNames[month]
    this.currentDay.month = month;
    this.currentDay.year = year;
    let firstDay = this.getFirstDay(year, month);
    this.currentDay.startsOn = firstDay;
    this.currentDay.endsOn = this.getDaysInMonth(year, month);
    this.currentDay.events = this.getEventsForMonth(year, month);
    this.currentDay.unfilteredEvents = this.currentDay.events;
    for (let i=firstDay; i < this.currentDay.endsOn + firstDay; i++) {
      this.currentDay.daysArray[i] = (i + 1 - firstDay);
    }
    if (!this.currentDay.daysArray[35]) {
      this.currentDay.daysArray.length = 35;
      this.currentDay.weeksArray.length = 5;
    } else {
      this.currentDay.weeksArray = [0, 7, 14, 21, 28, 35]
    }
    this.currentDay.events.forEach(event => {
      this.currentDay.eventsCount[event.day+firstDay] = ++this.currentDay.eventsCount[event.day+firstDay] || 1;
    })
    this.setApproachingMonth(year, month);
    this.setLastMonth(year, month);
  }
f
  gotToToday() {
    const today = new Date
    this.today = today
    this.setcurrentDay(today.getFullYear(), today.getMonth());
    this.setCurrentWeek(this.getWeekFromDate(today));
  }

  nextMonth() {
    if (this.currentDay.month == 11) {
      this.setcurrentDay(this.currentDay.year+1, 0)
    } else {
      this.setcurrentDay(this.currentDay.year, this.currentDay.month+1)
    }
    this.currentDay.week = 0;
  }

  previousMonth() {
    if (this.currentDay.month == 0) {
      this.setcurrentDay(this.currentDay.year-1, 11)
    } else {
      this.setcurrentDay(this.currentDay.year, this.currentDay.month-1)
    }
  }

  nextWeek() {
    if (this.currentDay.daysArray[this.currentDay.weeksArray[this.currentDay.week+1]]){
      this.currentDay.week++
    } else {
      this.lastMonth.daysArray = this.currentDay.daysArray
      this.nextMonth();
      if (!!this.lastMonth.daysArray[this.currentDay.weeksArray[this.currentDay.week]+6]) {
        this.currentDay.week=0;
      } else {
        this.currentDay.week=1;
      }
    }
  }

  previousWeek() {
    if (this.currentDay.week != 0) {
      this.currentDay.week--
    } else {
      if (this.currentDay.daysArray[0]) {
        this.previousMonth();
      this.currentDay.week = (Math.floor(this.currentDay.daysArray.length/7)-1)
      } else {
        this.previousMonth();
        this.currentDay.week = (Math.floor(this.currentDay.daysArray.length/7)-2)
      }
    }
  }

  openForm() {
    if (!this.currentDay.formOpened) {
      this.currentDay.formOpened = true;
      this.newEventInfo.tempEvent = true
      const newDate = this.newEventInfo.newEventDate
      const date = [newDate.getMonth()+1, newDate.getDate(), newDate.getFullYear()]
      const newEvent = {
        title: '(No Title)',
        year: date[2],
        month: date[0] - 1,
        day: date[1] -1,
        start: this.newEventInfo.newEventTime[0].value,
        end: this.newEventInfo.newEventTime[1].value,
        guests: '',
        location: '',
        description: '',
        type: 'audit'
      }
      this.addEvent(newEvent)
    }
  }

  closeForm() {
    this.currentDay.formOpened = false;
    this.newEventInfo.tempEvent = false
    this.userEvents.pop()
    this.setcurrentDay(this.currentDay.year, this.currentDay.month)
  }

  addEvent(newEvent) {
    this.userEvents.push(newEvent)
    this.setcurrentDay(this.currentDay.year, this.currentDay.month);
  }

  addEventAt(time, day) {
    const startsOn = this.currentDay.startsOn
    const week = this.currentDay.week;
    const dayOfMonth = week * 7 - startsOn + day + 1
    const date = new Date(`${this.currentDay.month+1}/${dayOfMonth}/${this.currentDay.year}`)
    this.newEventInfo.newEventDate = date
    const times = this.convertTime(time)
    this.newEventInfo.newEventTime[0] = times[0]
    this.newEventInfo.newEventTime[1] = times[1]
    this.openForm()
    this.newEventInfo.tempEvent = true
  }

  convertTime(time) {
    let hour = time.toString().slice(0, 2);
    let hour2 = parseInt(hour) + 2
    let time2 = hour2 + '00'
    if (time2.length < 4) time2 = '0' + time2
    const dayTime = hour >= 12 ? 'pm' : 'am'
    const dayTime2 = hour2 >= 12 ? 'pm' : 'am'
    hour = hour > 12 ? hour - 12 : hour;
    hour2 = hour2 > 12 ? hour2 - 12 : hour2;
    hour = hour[0] == '0' ? hour.substr(1) : hour
    const minute = time.slice(2, 4);
    return [
      {
        element: `${hour}:${minute} ${dayTime}`,
        value: time
      },
      {
        element: `${hour2}:${minute} ${dayTime2}`,
        value: time2
      }
    ]
  }

  constructor() { }
}
