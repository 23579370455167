import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { ProfileComponent } from './components/profile/profile.component';
import { NotificationsComponent } from './components/notifications/notifications.component'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild(HeaderComponent, { static: false }) header: any
  @ViewChild(NotificationsComponent, { static: false }) notifications: any
  @ViewChild(ProfileComponent, { static: false }) profile: any
  title = 'conseqta-case-management-web';

  showElements: boolean;
  panelStatus: boolean = false;
  profStatus: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router
  ) {

  }

  ngOnInit() {
    this.panelStatus = false;
    this.profStatus = false;
    // Show or hide header/sidebar/message center in app.component.html
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showElements = this.activatedRoute.firstChild.snapshot.data.showElements !== false;
      }
    })
  }
  receiveValue($event: any) {
    this.panelStatus = $event;
  
  }
  receiveProf($event: any) {
    this.profStatus = $event;
  }
  receivePanelChange($event: any) {
    this.panelStatus = $event;


  }
  receiveProfChange($event: any) {
    this.profStatus = $event;
   

  }

}
