<div id="case-form-wrap" *ngIf="_form.showForm">

    <div id="case-form">
        <h6>New Case</h6>
        <div id="close" (click)="changeValue()"><img src='../../../../assets/photos/close.svg'></div>
        <form>
            <div id="section-one">
                <div>
                    <label for="case-name" *ngIf='dropDowns[3].selected !== "Allegation"'>Case Name</label>
                    <label for="case-name" *ngIf='dropDowns[3].selected === "Allegation"'>Alleger</label>
                    <input id="case-name" type="text" placeholder="Type case name here..." (keyup)="inputEntry($event, 0)">
                </div>
                <div>
                    <label>Case Type</label>
                    <div class="dropDown medium">
                        <p>{{dropDowns[3].selected}}</p>
                        <div (click)="toggleMenu($event, 3)"><img src="../../../../assets/photos/down.svg"></div>
                    </div>
                    <div class="ddList medium" id="case-list" *ngIf="dropDowns[3].show">
                        <ul>
                            <li *ngFor="let type of dropDowns[3].dd" (click)="selectItem($event, 3)">{{type.type}}</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <label *ngIf='dropDowns[3].selected !== "Allegation"'>Investigation Type</label>
                    <label *ngIf='dropDowns[3].selected === "Allegation"'>Source</label>

                    <div *ngIf='dropDowns[3].selected !== "Allegation"' class="dropDown large">
                        <p>{{dropDowns[4].selected}}</p>
                        <div (click)="toggleMenu($event, 4)"><img src="../../../../assets/photos/down.svg"></div>
                    </div>
                    <div class="ddList large" id="in-list" *ngIf="dropDowns[4].show">
                        <ul>
                            <li *ngFor="let type of dropDowns[4].dd" (click)="selectItem($event, 4)">
                                {{type.type}}</li>
                        </ul>
                    </div>

                    <div *ngIf='dropDowns[3].selected === "Allegation"' class="dropDown large">
                        <p>{{dropDowns[6].selected}}</p>
                        <div (click)="toggleMenu($event, 6)"><img src="../../../../assets/photos/down.svg"></div>
                    </div>
                    <div class="ddList large" id="in-list" *ngIf="dropDowns[6].show">
                        <ul>
                            <li *ngFor="let type of dropDowns[6].dd" (click)="selectItem($event, 6)">
                                {{type.type}}</li>
                        </ul>
                    </div>

                </div>
                <div>
                    <label for="pro-off">Associated Program Office</label>
                    <input id="pro-off" type="text" placeholder="Type office here..." (keyup)="inputEntry($event, 1)">
                </div>
                <div *ngIf='dropDowns[3].selected !== "Allegation"'>
                    <label for="pro-off">Correlated Allegations</label>
                    <input id="pro-off" type="text" placeholder="Type allegation numbers here..." (keyup)="inputEntry($event, 2)">
                </div>
            </div>
            <div id="section-two">
                <div>
                   <div id="tag-header">
                    <label for="case-tag" >Case Tags</label>
                    </div>
                    <input  #caseTag id="case-tag" type="text" placeholder="Type case tags here..." (keyup.enter)="addTag()">
                    
                </div>
                <div>
                    <div id="case-tag-list">
                        <ul>
                            <li class="case-tag" *ngFor="let case of caseTags let i = index" value={{case.case}}><p>{{case.case}}</p><div #tagClose (click)="removeTag(case.case)" class="tag-close"><img src="../../../../assets/photos/white-close.svg"></div></li>
                            
                        </ul>
                    </div>
                </div>
                <div>
                    <label *ngIf='dropDowns[3].selected !== "Allegation"'>Case Due Date </label>
                    <label *ngIf='dropDowns[3].selected === "Allegation"'>Date Received</label>
                    <div class="date-flex">
                        <div class="dropDown tiny">
                            <p>{{dropDowns[2].selected}}</p>
                            <div (click)="toggleMenu($event, 2)"><img src="../../../../assets/photos/down.svg"></div>
                        </div>
                        <div class="ddList tiny" id="month-list" *ngIf="dropDowns[2].show" >
                            <ul>
                                <li *ngFor="let month of dropDowns[2].dd" (click)="selectItem($event, 2)">{{month.month}}</li>
                            </ul>
                        </div>

                        <div class="dropDown tiny">
                            <p>{{dropDowns[0].selected}}</p>
                            <div (click)="toggleMenu($event, 0)"><img src="../../../../assets/photos/down.svg"></div>
                        </div>
                        <div class="ddList tiny" id="day-list" *ngIf="dropDowns[0].show">
                            <ul>
                                <li *ngFor="let day of dropDowns[0].dd" (click)="selectItem($event, 0)">{{day.day}}</li>
                            </ul>
                        </div>
                        <div class="dropDown small">
                            <p>{{dropDowns[1].selected}}</p>
                            <div  (click)="toggleMenu($event, 1)"><img src="../../../../assets/photos/down.svg"></div>
                        </div>
                        <div class="ddList small" id="year-list" *ngIf="dropDowns[1].show">
                            <ul>
                                <li *ngFor="let year of dropDowns[1].dd" (click)="selectItem($event, 1)">{{year.year}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <label *ngIf='dropDowns[3].selected === "Investigation"'>Subject</label>
                    <label *ngIf='dropDowns[3].selected === "Allegation"'>Category</label>
                    <label *ngIf='dropDowns[3].selected === "Audit"'>Agency</label>
                    <div class="dropDown large">
                        <p>{{dropDowns[5].selected}}</p>
                        <div (click)="toggleMenu($event, 5)"><img src="../../../../assets/photos/down.svg"></div>
                    </div>
                    <div class="ddList large" id="subject-list" *ngIf="dropDowns[5].show">
                        <ul>
                            <li *ngFor="let type of dropDowns[5].dd" (click)="selectItem($event, 5)">{{type.type}}</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <label *ngIf='dropDowns[3].selected === "Investigation"'>Case Plan</label>
                    <div *ngIf='dropDowns[3].selected === "Investigation"' class="upload-container">
                        <app-case-plan-file></app-case-plan-file>
                </div>
                </div>


            </div>
            <div id="section-three">
                <div>
                    <label for="description">Case Description</label>
                    <textarea id="description" placeholder="Type case description here..." (keyup)="inputEntry($event, 3)"></textarea>
                </div>
                <div>
                    <label for="goal">Strategic Goal</label>
                    <textarea id="goal" placeholder="Type strategic goal here..." (keyup)="inputEntry($event, 4)"></textarea>
                </div>
                <div>

                    <label>Case Files</label>
                    <div class="upload-container">
                        <app-case-files-file></app-case-files-file>
                </div>
                </div>

            </div>
        </form>
        <footer>
            <button type="click"  (click)="onSubmit()">Create</button>
            <button type="click" (click)="changeValue()">Cancel</button>
        </footer>
    </div>

</div>