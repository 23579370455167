import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '../../services/notifications.service'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input() panelStatus: boolean;
  @Output() panelStatusChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  notiArray:any = [];
  notiFive: any = [];
  fullArray: boolean = false;

  @ViewChild('listDiv', {static:false}) listDiv: any;
  @ViewChild('bottomDiv', {static:false}) bottomDiv:any;

  constructor(private _noti:NotificationsService){ }

  ngOnInit(): void {
    this.getNotifications()
  }

  //toggle notifications with x button
  changeValue() {
    this.panelStatus = !this.panelStatus;
    this.panelStatusChange.emit(this.panelStatus)
    this.fullArray = false;
  }
  getNotifications(){
    this._noti.getNoti().subscribe(data => {
      this.notiArray = []
      this.notiArray = data
      this.notiArray = this.notiArray.collection
      this.notiFive = this.notiArray.slice(0,5)
  
    }
    )
  }
  showAll(){
    this.fullArray = true
    this.listDiv.nativeElement.classList.toggle('scroll');
    this.bottomDiv.nativeElement.classList.toggle('hide');
  }
  
}
