import { Component, OnInit } from '@angular/core';
import * as data from './piechart.json';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-task-breakdown',
  templateUrl: './task-breakdown.component.html',
  styleUrls: ['./task-breakdown.component.scss']
})
export class TaskBreakdownComponent implements OnInit { 

  dataObj = {
    labels: ["North Anna Plant", "Universal Safety Regulations", "Doe Background Check"],
    datasets: [
      {
          fill: true,
          backgroundColor: [
            '#023B68',
            '#00CDDD',
            '#81D36A'
          ],
          data: data.pieCharts,
          borderColor:	[
            '#023B68',
            '#00CDDD',
            '#81D36A'
          ],
          borderWidth: [2,2,2]
      }
    ]
  }
  
  chartOptions = {
    cutoutPercentage: 80,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'right',
      labels: {
        padding: 20,
        fontFamily: 'open sans',
        boxWidth: 7,
        usePointStyle: true
      }
    }
  }

  ngOnInit() {
      
    let chart = new Chart('pie-chart', {});
    
  }

}