import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CaseFormServiceService {
  @Injectable()
  showForm: boolean = false;
  constructor() { }
}
