<div id="home-dashboard">
    <div id="grid-container">
    <div>
        <app-task-line-graph></app-task-line-graph>
    </div>
    <div>
        <app-task-breakdown></app-task-breakdown>
    </div>
    <div><app-case-list></app-case-list></div>
    </div>
    <div id="calendar-container">
        <div class='calendar-top-bar'>
            <p>Schedule Calendar</p>
            <button routerLink="/calendar">See All</button>
        </div>
        <div class='mini-calendar'>
            <div class='days'>
                <p>S</p>
                <p>M</p>
                <p>T</p>
                <p>W</p>
                <p>T</p>
                <p>F</p>
                <p>S</p>
            </div>
            <div class='event-rows'>
                <div 
                    class='event-card-wrapper' 
                    *ngFor = 'let event of weeksEvents' 
                    [style.left.%]='calculateEventX(event.start)'
                    [style.top.%]='calculateEventY(event.day)'
                    [style.width.%]='calculateEventWidth(event.start, event.end)'
                >
                    <div 
                        class='event-card'
                        [ngStyle]="{'background-color': getEventColor1(event.type), 'color': event.type == 'audit' ? 'white' : 'black'}"
                        (mouseenter)='showTooltip($event)' (mouseleave)='hideTooltip($event)'
                    >
                        <p>{{ event.title }}</p>
                        <p>{{ convertTime(event.start) }} - {{ convertTime(event.end) }}</p>
                    </div>
                    <section (mouseenter)='showTooltip($event)' (mouseleave)='hideTooltip($event)' class='event-tooltip hidden'>
                        <p>{{ event.title }}</p>
                    </section>
                </div>

                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div id="deadlines">
            <app-deadlines></app-deadlines>
        </div>
    </div>
</div>
<app-case-form></app-case-form>