import { Component, OnInit, HostListener } from '@angular/core';
import { CaseService } from '../../../services/case.service';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { NavigationEnd, Router } from '@angular/router';
import { CaseFormServiceService } from '../../../services/case-form-service.service'


@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss']
})
export class CaseListComponent implements OnInit {

 


  faSearch = faSearch;

  p: number = 1;
  currRoute = 'home'
  collection: any = []
  input = ''
  output: any = []
  filterArr = [
    { type: 'All' },
    { type: 'Allegation' },
    { type: 'Audit' },
    { type: 'Investigation' },
  ]
  showFilter: boolean = false
  filter: string = ''

  sortArr = [
    { option: 'Default' },
    { option: "Active tasks (High to Low)" },
    { option: "Active tasks (Low to High)" },
    { option: "Due Date (Soon to Distant)" },
    { option: "Due Date (Distant to Soon)" }
  ]
  showSort: boolean = false
  sort: string = ''
  items: number = 10

  constructor(private _case: CaseService, private router: Router, private _form: CaseFormServiceService) {
  
    
    // shows what route the component is on
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // get current route without leading slash `/`
        const eventUrl = /(?<=\/).+/.exec(event.urlAfterRedirects);
        const currentRoute = (eventUrl || []).join('');
        // set currRoute property with the value of the current route
        this.currRoute = currentRoute;
      }
    });
  }

  ngOnInit() {
    this.getCase()

  }
  
  //toggle form
  changeValue(){
    this._form.showForm = !this._form.showForm

  }

  getCase() {

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    this._case.getCases().subscribe(data => {

      this.collection = []
      this.collection = data

      if (this.currRoute === 'home' || this.currRoute === 'case') {
        //filters out cases that arent current
        this.collection = this.collection.details.filter(component => {
          return (
            new Date(component.dueDate) >= today ? 1 : 0
          )
        })
        this.output = this.collection

      } else if(this.currRoute === 'archive') {

        this.collection = this.collection.details
        this.output = this.collection
      }

    }
    )
  }

  //======= SEARCH =======//

  searchCollection(event: KeyboardEvent) {

    this.input = (event.target as HTMLInputElement).value.toUpperCase();

    let filteredCollection = this.collection.filter(component => {
      return (
        component.name.toUpperCase().includes(this.input) || component.type.toUpperCase().includes(this.input) || component.org.toUpperCase().includes(this.input)
      )
    })
    this.output = filteredCollection;

    return this.output
  }

  //======= FILTER =======//

  clickShowFilter() {
    if (this.showSort === true) {
      this.showFilter = !this.showFilter
      this.showSort = !this.showSort;
    } else {
      this.showFilter = !this.showFilter
    }

  }

  setFilter(event: any) {
    this.filter = event.target.innerText.toUpperCase();
    if (this.filter === 'ALL') {
      this.output = this.collection;
      this.showFilter = !this.showFilter
    } else {
      let filteredCollection = this.collection.filter(component => {
        return (
          component.type.toUpperCase().includes(this.filter)
        )
      })
      this.output = filteredCollection;
      this.showFilter = !this.showFilter
      return this.output
    }
  }

  // ======= SORT ======= //

  clickShowSort() {
    if (this.showFilter === true) {
      this.showSort = !this.showSort
      this.showFilter = !this.showFilter
    } else {
      this.showSort = !this.showSort
    }

  }

  setSort(event: any) {
    this.sort = event.target.innerText

    if (this.sort === 'Default') {
      this.getCase()
      this.showSort = !this.showSort
      return this.output;
    } else if (this.sort === 'Active tasks (High to Low)') {
      let sorted = this.collection.sort((a, b) => {
        return Number(a.activeTasks) < Number(b.activeTasks)
      })
      this.output = sorted;
      this.showSort = !this.showSort
      return this.output;
    } else if (this.sort === 'Active tasks (Low to High)') {
      let sorted = this.collection.sort((a, b) => {
        return Number(a.activeTasks) > Number(b.activeTasks)
      })
      this.output = sorted;
      this.showSort = !this.showSort
      return this.output;
    } else if (this.sort === 'Due Date (Soon to Distant)') {
      let sorted = this.collection.sort((a, b) => {
        return new Date(a.dueDate) > new Date(b.dueDate)
      })
      this.output = sorted;
      this.showSort = !this.showSort
      return this.output;
    } else if (this.sort === 'Due Date (Distant to Soon)') {
      let sorted = this.collection.sort((a, b) => {
        return new Date(a.dueDate) < new Date(b.dueDate)
      })
      this.output = sorted;
      this.showSort = !this.showSort
      return this.output;
    }

  }


}
